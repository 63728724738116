import { setReduxLanguage } from "@/redux/slices/languageSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ar from "../../locales/ar/common.json";
import en from "../../locales/en/common.json";

export function useLanguage() {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  const changeLanguage = (lang) => {
    dispatch(setReduxLanguage(lang));
  };

  return { language, setReduxLanguage: changeLanguage };
}

const translations = { ar, en };

export function useTranslation() {
  const language = useSelector((state) => state.language);
  const currentTranslations = translations[language];

  const t = (key, placeholders = {}) => {
    const translation =
      key.split(".").reduce((obj, part) => obj?.[part], currentTranslations) ||
      key;

    return translation.split(/({\w+})/g).map((part, index) => {
      const match = part.match(/{(\w+)}/);
      if (match) {
        const placeholderKey = match[1];
        return (
          <strong key={index} className="font-bold">
            {placeholders[placeholderKey]}
          </strong>
        );
      }
      return part;
    });
  };

  // Utility function to return plain string version of the translation
  const tString = (key, placeholders = {}) => {
    const translation = t(key, placeholders);
    // Join elements as string in case translation has JSX
    return translation
      .map((part) => (typeof part === "string" ? part : part.props.children))
      .join("");
  };

  return { t, tString };
}
