export let primaryColor = "#48808F";

if (typeof window !== "undefined") {
  const primary =
    JSON.parse(localStorage.getItem("accountSettings"))?.primary_color ||
    primaryColor;
  const rgbColor = hexToRgb(primary);

  primaryColor = primary;

  document.documentElement.style.setProperty("--primary-color", rgbColor);
}

export function hexToRgb(hex) {
  // Convert hex code to RGB
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);
  return `${r}, ${g}, ${b}`;
}

const appColors = {
  primary: primaryColor,
};

export default appColors;
