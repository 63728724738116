import MapIntegration from "./mapIntegration";
import Image from "next/image";
import React, { useState, useEffect, useCallback } from "react";

export default function MapDialog({
  data = [],
  sidebar = null,
  selectedBranch = {},
  onSelectedMarker = () => {},
  closeDialog = () => {},
}) {
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [nearestBranch, setNearestBranch] = useState(null);

  const findNearestBranch = useCallback(() => {
    let closestBranch = null;
    let minDistance = Infinity;

    data.forEach((branch) => {
      if (!branch.lat_lng) return null;
      const [lat, lng] = branch.lat_lng.split(",").map(Number);
      const distance = calculateDistance(
        selectedCoordinates.lat,
        selectedCoordinates.lng,
        lat,
        lng
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestBranch = branch;
      }
    });

    setNearestBranch(closestBranch);
  }, [data, selectedCoordinates]);

  const handleSelectNearestBranch = () => {
    if (nearestBranch) {
      onSelectedMarker(nearestBranch);
    }
  };

  const changeInSearch = (places) => {
    if (places && places.length > 0) {
      const place = places[0];
      if (place) {
        const { lat, lng } = place.geometry.location;
        setSelectedCoordinates({ lat: lat(), lng: lng() });
      } else {
        console.log("Place not found");
      }
    }
  };

  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLng = (lng2 - lng1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  useEffect(() => {
    if (selectedCoordinates && data.length > 0) {
      findNearestBranch();
    }
  }, [selectedCoordinates, data, findNearestBranch]);

  const handleMap = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setSelectedCoordinates({ lat, lng });
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="relative rounded-3xl flex items-center justify-center bg-white"
        style={{ width: "1069px", height: "597px", overflow: "hidden" }}
      >
        <MapIntegration
          onSelectedMarker={onSelectedMarker}
          data={data}
          selectedBranch={selectedBranch}
          handleMapClick={handleMap}
          changeInSearch={changeInSearch}
          selectedCoordinates={selectedCoordinates}
        />
        <div
          onClick={closeDialog}
          className="absolute top-[8px] right-[8px] p-[3px] cursor-pointer rounded-[100px] justify-start items-start gap-2.5 inline-flex z-50"
        >
          <div className="w-[33.33px] h-[33.33px] flex justify-center items-center bg-[#FAFAFA] rounded-full">
            <Image src="/close.svg" alt="close" width={12} height={12} />
          </div>
        </div>
        {sidebar && (
          <div className="absolute right-0 bottom-1 h-[33.3%] md:h-[98%] w-full overflow-x-auto md:w-[31%] bg-white p-4 my-2 mr-2 rounded-3xl">
            {React.cloneElement(sidebar, {
              closeDialog,
              selectedCoordinates,
              setSelectedCoordinates,
            })}
          </div>
        )}
        {nearestBranch && (
          <div
            className="absolute bottom-2 left-44 bg-white px-3 py-2 rounded-2xl shadow z-30 w-[408px] h-[170px] cursor-pointer"
            onClick={handleSelectNearestBranch}
          >
            <h3 className="mb-2 text-[16px] font-semibold leading-[22px]">
              Nearest Branch
            </h3>
            <div>
              <>
                <p className="mb-2 text-[16px] font-semibold leading-[22px]">
                  {nearestBranch.title}
                </p>
                <div className="h-7 flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch justify-between items-center inline-flex">
                    <div className="justify-start items-center gap-1.5 flex">
                      <div className="text-[#34c759] text-sm font-normal font-['K2D'] leading-snug">
                        Open at {nearestBranch.open_at}
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-between items-center inline-flex">
                    <div className="justify-start items-center gap-1.5 flex">
                      <div className="text-[#34c759] text-sm font-normal font-['K2D'] leading-snug">
                        Close at {nearestBranch.close_at}
                      </div>
                    </div>
                  </div>
                  <div className="justify-start items-start gap-1.5 inline-flex">
                    <div className="text-[#0c0505] text-sm font-normal font-['K2D'] leading-[21px]">
                      {nearestBranch.address}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
