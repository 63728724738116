import {
  GoogleMap,
  useLoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { useEffect, useRef } from "react";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 24.7136,
  lng: 46.6753,
};

const libraries = ["places"];

export default function MapIntegration({
  data = [],
  selectedBranch = "",
  onSelectedMarker = () => {},
  handleMapClick = () => {},
  changeInSearch = () => {},
  selectedCoordinates,
}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_MAPS_API_KEY,
    libraries: libraries,
  });

  const mapRef = useRef(null);
  const searchBoxRef = useRef(null);

  useEffect(() => {
    if (selectedBranch && selectedBranch.lat_lng) {
      const [lat, lng] = selectedBranch.lat_lng.split(",").map(Number);
      if (mapRef.current) {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(10);
      }
    }
  }, [selectedBranch]);

  if (!isLoaded) return <div> Loading ... </div>;
  if (loadError) return <div>Error loading maps</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={
        selectedCoordinates
          ? selectedCoordinates
          : selectedBranch && selectedBranch.lat_lng
          ? {
              lat: Number(selectedBranch.lat_lng.split(",")[0]),
              lng: Number(selectedBranch.lat_lng.split(",")[1]),
            }
          : center
      }
      zoom={10}
      options={{
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        streetViewControl: false,
      }}
      onClick={handleMapClick}
      onLoad={(map) => (mapRef.current = map)}
    >
      {data.map((marker, index) => {
        if (!marker.lat_lng) return null;
        const [lat, lng] = marker.lat_lng.split(",").map(Number);
        return (
          <Marker
            key={index}
            position={{
              lat: lat,
              lng: lng,
            }}
            title={marker.title}
            onClick={() => onSelectedMarker(marker)}
          />
        );
      })}
      {selectedCoordinates && (
        <Marker
          position={{
            lat: selectedCoordinates?.lat,
            lng: selectedCoordinates?.lng,
          }}
          draggable={true}
          onDragEnd={handleMapClick}
        />
      )}
      <StandaloneSearchBox
        onLoad={(ref) => (searchBoxRef.current = ref)}
        onPlacesChanged={() => {
          const places = searchBoxRef.current.getPlaces();
          changeInSearch(places);
        }}
      >
        <input
          type="text"
          placeholder="Search"
          className="absolute top-2 left-4 md:left-20 bg-white p-2 rounded-full shadow z-[80px] w-[85%] md:w-[55%] lg:w-[60%]"
        />
      </StandaloneSearchBox>
    </GoogleMap>
  );
}
